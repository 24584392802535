<template>
    <v-card :loading="loading">
        <v-card-title class="text-h5">
            <v-btn
                class="mr-3"
                depressed
                color="primary"
                icon
                rounded
                @click="closeDialog"
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>

            {{ title }}
            <v-spacer></v-spacer>
            <v-btn
                v-if="!createForm"
                depressed
                color="primary"
                rounded
                icon
                class=""
                @click="update"
            >
                <v-icon>mdi-lead-pencil</v-icon>
            </v-btn>
        </v-card-title>
        <v-card-text>
            <v-form
                ref="form"
                v-model="valid"
                :disabled="!createForm && !isEditing"
            >
                <v-row>
                    <v-col cols="12">
                        <v-text-field
                            v-model="lead.name"
                            :readonly="!createForm && !isEditing"
                            :rules="[rules.required]"
                            prepend-icon="mdi-file-document-edit"
                            label="Name *"
                            required
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="6">
                        <v-autocomplete
                            v-model="lead.userId"
                            :items="users"
                            item-text="name"
                            item-value="id"
                            prepend-icon="mdi-account"
                            label="Account Manager *"
                            required
                            :rules="[rules.required]"
                        >
                            <template slot="item" slot-scope="{ item }">
                                <v-row>
                                    <p class="ma-0 ml-1 pa-0">
                                        {{ item.name }}
                                    </p>
                                    <h5 class="grey--text ma-0 ml-1 mt-1 pa-0">
                                        ({{ item.role }})
                                    </h5>
                                </v-row>
                            </template>
                        </v-autocomplete>
                    </v-col>
                    <v-col cols="6">
                        <v-autocomplete
                            v-model="lead.clientId"
                            :loading="loading"
                            :items="filterClients"
                            item-text="name"
                            item-value="id"
                            @change="selectClient"
                            prepend-icon="mdi-office-building"
                            label="Client *"
                            required
                            :rules="[rules.required]"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="6">
                        <v-autocomplete
                            multiple
                            v-model="lead.contacts"
                            @change="deleteSearch"
                            :search-input.sync="searchContacts"
                            :loading="loading"
                            :items="clientContacts"
                            item-text="name"
                            item-value="id"
                            prepend-icon="mdi-account-tie"
                            label="Client contact "
                        />
                    </v-col>
                    <v-col cols="6">
                        <v-autocomplete
                            v-model="lead.country"
                            :items="this.countries"
                            prepend-icon="mdi-earth"
                            label="Country"
                            @change="selectState"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="6">
                        <v-autocomplete
                            v-model="lead.state"
                            @change="selectCity"
                            :items="this.states"
                            prepend-icon="mdi-map-marker-radius"
                            label="State"
                        />
                    </v-col>
                    <v-col cols="6">
                        <v-combobox
                            v-model="lead.city"
                            :filter="filter"
                            :hide-no-data="!search"
                            :items="this.cities"
                            hide-details
                            prepend-icon="mdi-city"
                            :search-input.sync="search"
                            hide-selected
                            label="City"
                            flat
                            @focus="enabled = false"
                            @blur="enabled = true"
                        >
                            <template v-slot:item="{ index, item }">
                                <v-text-field
                                    v-if="editing === item"
                                    v-model="editing.text"
                                    autofocus
                                    flat
                                    background-color="transparent"
                                    hide-details
                                    solo
                                    @keyup.enter="edit(index, item)"
                                ></v-text-field>
                                <span v-else>
                                    {{ item.text }}
                                </span>
                                <v-spacer></v-spacer>
                            </template>
                        </v-combobox>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="6">
                        <v-menu
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                            v-model="menu2"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="lead.invitationDate"
                                    label="Invitation Date*"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    required
                                    hide-details
                                    class="pt-0"
                                    :rules="[rules.required]"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="lead.invitationDate"
                                @input="menu2 = false"
                            ></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="6">
                        <v-menu
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                            v-model="menu"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="lead.requestDate"
                                    label="Due Date*"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    required
                                    hide-details
                                    class="pt-0"
                                    :rules="[rules.required]"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="lead.requestDate"
                                @input="menu = false"
                            ></v-date-picker>
                        </v-menu>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-text-field
                            v-model="lead.notes"
                            prepend-icon="mdi-contacts"
                            label="Notes"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-file-input
                            v-if="createForm"
                            :disabled="!createForm"
                            v-model="files"
                            prepend-icon="mdi-file-document"
                            label="Bid documents"
                            multiple
                            small-chips
                            @change="onFilesPicked"
                        ></v-file-input>
                    </v-col>
                </v-row>
                <v-row class="align-end my-2" no-gutters>
                    <v-col>
                        <v-combobox
                            v-if="createForm"
                            v-model="lead.tags"
                            :filter="filter"
                            :hide-no-data="!searchTags"
                            :items="tags"
                            hide-details
                            :search-input.sync="searchTags"
                            hide-selected
                            multiple
                            small-chips
                            flat
                            prepend-icon="mdi-tag-multiple"
                        >
                            <template v-slot:no-data>
                                <v-list-item>
                                    <v-chip color="grey lighten-3" small>
                                        {{ searchTags }}
                                    </v-chip>
                                </v-list-item>
                            </template>
                            <template
                                v-slot:selection="{
                                    attrs,
                                    item,
                                    parent,
                                    selected,
                                }"
                            >
                                <v-chip
                                    :disabled="!createForm && !isEditing"
                                    v-if="item === Object(item)"
                                    v-bind="attrs"
                                    color="grey lighten-3"
                                    :input-value="selected"
                                    small
                                >
                                    <span class="pr-2">
                                        {{ item.text }}
                                    </span>
                                    <v-icon
                                        small
                                        @click="parent.selectItem(item)"
                                    >
                                        mdi-close-circle
                                    </v-icon>
                                </v-chip>
                            </template>
                            <template v-slot:item="{ index, item }">
                                <v-text-field
                                    v-if="editing === item"
                                    v-model="editing.text"
                                    autofocus
                                    flat
                                    background-color="transparent"
                                    hide-details
                                    solo
                                    @keyup.enter="edit(index, item)"
                                ></v-text-field>
                                <v-chip v-else color="grey lighten-3" small>
                                    {{ item.text }}
                                </v-chip>
                                <v-spacer></v-spacer>
                            </template>
                        </v-combobox>
                    </v-col>
                </v-row>
            </v-form>

            <small v-if="createForm">* indicates required field</small>
        </v-card-text>

        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                v-if="createForm"
                text
                color="primary"
                :loading="loading"
                :disabled="!valid || !enabled"
                @click="saveLead"
            >
                Save
            </v-btn>
            <v-btn
                v-if="isEditing"
                :disabled="!valid || !enabled"
                text
                color="primary"
                :loading="loading"
                @click="saveLead"
            >
                Save
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import _ from 'lodash'
import { mapActions, mapMutations } from 'vuex'
import API from '@/services/api'
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'
import codesCurrency from '@/assets/codes-all_json.json'
const countrycitystate = require('countrycitystatejson')
import { storage } from '@/services/firebase'
import { objDiff } from '@/helpers/objDiff'
export default {
    name: 'QuoteForm',
    props: {
        title: {
            type: String,
            required: true,
        },
        originalLead: {
            type: Object,
        },
        settings: Array,
        createForm: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            itemsLanguage: ['English', 'Spanish'],
            itemsCurrency: null,
            clientId: null,
            user: JSON.parse(localStorage.getItem('userId')),
            folderLeads: 'leads_documents',
            company: JSON.parse(localStorage.getItem('company')),
            folderUsersPictures: 'users_pictures',
            folderClientsPictures: 'clients_pictures',
            loading: false,
            error: false,
            clientContacts: [],
            clientProjects: [],
            errorMsg: null,
            valid: false,
            attach: [],
            path: null,
            files: [],
            isEditing: false,
            rules: {
                required: v => !!v || 'Required',
                email: v => /.+@.+\..+/.test(v) || 'Not a valid E-mail',
            },
            deleteDialog: false,
            deleteError: false,
            listCountries: null,
            countries: [],
            cities: [],
            states: [],
            shortNameCity: null,
            editing: null,
            search: null,
            searchTags: null,
            tags: [],
            model: [],
            companyId: JSON.parse(localStorage.getItem('company')),
            users: [],
            requestDate: '',
            invitationDate: '',
            menu: false,
            menu2: false,
            originalContacts: [],
            originalFiles: [],
            originalClient: '',
            lead: {},
            clients: [],
            enabled: true,
            searchContacts: null,
        }
    },
    watch: {
        model(val, prev) {
            if (val.length === prev.length) return
            this.searchTags = null
            this.model = val.map(v => {
                if (typeof v === 'string') {
                    v = {
                        text: v,
                    }
                    this.tags.push(v)
                    this.error = false
                }
                return v
            })
        },
    },
    computed: {
        leadDiff: function() {
            if (!this.createForm) {
                return objDiff(this.originalLead, this.lead)
            } else {
                return null
            }
        },
        filterClients: function() {
            return this.clients.filter(client => client.enabled === true)
        },
    },
    async mounted() {
        try {
            let companyInfo = this.settings.find(s => s.name === 'Company')
            this.tags = companyInfo.tags?.map(t => {
                return {
                    text: t,
                    value: t,
                }
            })
            const {
                data: { users },
            } = await API.getLiteUsers()
            this.users = users
            if (this.createForm == false) {
                this.originalClient = this.lead.clientId
                this.originalContacts = this.lead.contacts
                this.originalFiles = this.lead.files
                this.lead.requestDate = this.formatDate(
                    this.lead.requestDate._seconds
                )
                this.lead.invitationDate = this.formatDate(
                    this.lead.invitationDate._seconds
                )
                await this.selectClient(this.lead.clientId)
                this.selectState(this.lead.country)
                this.lead.tags = this.lead.tags?.map(t => {
                    return {
                        text: t,
                        value: t,
                    }
                })
                if (this.lead.files.length > 0) {
                    this.files = this.lead.files
                }
            }
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        }
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        ...mapActions([
            'addUsersAction',
            'addClientsAction',
            'addContactsAction',
        ]),
        closeDialog: function() {
            this.$refs.form.reset()
            this.error = false
            this.errorMsg = null
            this.isEditing = false
            this.image = null
            this.$emit('closeDialog')
        },
        onFilesPicked: function() {
            this.attach = []
            this.files.forEach(element => {
                var indexDot = element.name.indexOf('.')
                const typeDoc = element.name.slice(
                    indexDot,
                    element.name.length
                )
                const nameFile = uuidv4() + typeDoc
                const docs = {
                    name: element.name,
                    file: nameFile,
                    attach: false,
                }
                this.attach.push(docs)
            })
        },
        formatDate(seconds) {
            return `${moment.unix(seconds).format('YYYY-MM-DD')}`
        },
        formatDate2(seconds) {
            return moment.unix(seconds).format('MM/DD/YYYY')
        },
        difference(array1, array2) {
            const set2 = new Set(array2)
            return array1.filter(element => !set2.has(element))
        },

        saveLead: function() {
            if (this.createForm) {
                this.createLead()
            } else {
                this.updateLead()
            }
        },
        selectClient: function(event) {
            this.clientId = event
            this.getContactsByClient()
        },
        async getContactsByClient() {
            try {
                this.loading = true
                const {
                    data: { contacts },
                } = await API.getContactsByClient({
                    clientId: this.clientId,
                })
                this.clientContacts = contacts
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        createLead: async function() {
            try {
                this.loading = true
                this.error = false
                this.errorMsg = null
                this.lead.files = this.attach
                if (!this.lead.clientId) {
                    this.lead.clientId = ''
                }
                const client = this.clients.find(
                    client => client.id === this.clientId
                )
                if (client) {
                    this.lead.clientName = client.name
                    this.lead.currency = client.currency
                }
                const indexExchanges = this.settings.findIndex(
                    u => u.name === 'Exchange'
                )
                const settingExchange = this.settings[indexExchanges]
                const index = settingExchange.exchanges.findIndex(
                    u => u.currency === this.lead.currency
                )
                if (index >= 0) {
                    this.lead.exchange = parseInt(
                        settingExchange.exchanges[index].money
                    )
                } else {
                    this.lead.exchange = 0
                }
                // Assign only the name of the city
                if (this.lead.city && this.lead.city.text) {
                    this.lead.city = this.lead.city.text
                }

                if (this.model.length > 0) {
                    const selectedTags = this.model.map(t => t.text)
                    this.lead.tags = selectedTags
                    const index = this.settings.findIndex(
                        s => s.name === 'Company'
                    )
                    let tempTags = []
                    selectedTags.forEach(t => {
                        if (!this.settings[index].tags.includes(t)) {
                            tempTags.push(t)
                        }
                    })
                    this.settings[index].tags = [
                        ...this.settings[index].tags,
                        ...tempTags,
                    ]
                    await API.updateCompany(this.companyId, {
                        settingId: this.settings[index].id,
                        tags: this.settings[index].tags,
                    })
                }

                this.lead.collaborators =
                    this.user == this.lead.userId
                        ? [this.user]
                        : [this.user, this.lead.userId]
                // clean data
                Object.keys(this.lead).forEach(key => {
                    if (
                        this.lead[key] === undefined ||
                        this.lead[key] === null
                    ) {
                        delete this.lead[key]
                    }
                })
                if (this.lead.clientContact) {
                    this.lead.contacts = [this.lead.clientContact]
                    delete this.lead.clientContact
                }
                if (this.lead.tags && this.lead.tags.length > 0) {
                    let tags2 = []
                    this.lead.tags.forEach(t => {
                        tags2.push(t.text)
                    })
                    this.lead.tags = tags2
                }
                const {
                    data: { lead },
                } = await API.createLead(this.lead)
                this.path = `${this.company}/${this.folderLeads}/${lead.id}`
                const storageRef = storage().ref(this.path)
                for (let i = 0; i < this.attach.length; i++) {
                    const leadDocRef = storageRef.child(this.attach[i].file)

                    var metadata = {
                        customMetadata: {
                            filename: this.files[i].name,
                        },
                    }
                    await leadDocRef.put(this.files[i], metadata)
                }
                lead.requestDateShow = this.formatDate2(
                    lead.requestDate._seconds
                )
                lead.invitationDateShow = this.formatDate2(
                    lead.invitationDate._seconds
                )
                this.$emit('addLead', lead)
                this.closeDialog()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async updateLead() {
            try {
                this.loading = true
                this.lead.accountManager = this.getUser(this.lead.userId)
                this.lead.clientName = this.clients.filter(
                    client => client.id == this.lead.clientId
                )[0].name
                if (this.lead.tags != null) {
                    let tags2 = []
                    this.lead.tags.forEach(t => {
                        tags2.push(t.text)
                    })
                    this.lead.tags = tags2
                }
                if (this.attach.length > 0) {
                    const path = `${this.companyId}/leads_documents/${this.lead.id}`
                    if (this.lead.files.length > 0) {
                        await this.deleteAllFilesInPath(
                            path,
                            _.cloneDeep(this.lead.files)
                        )
                    }
                    this.lead.files = _.cloneDeep(this.attach)
                    this.path = `${this.company}/${this.folderLeads}/${this.lead.id}`
                    const storageRef = storage().ref(this.path)

                    for (let i = 0; i < this.attach.length; i++) {
                        const leadDocRef = storageRef.child(this.attach[i].name)

                        var metadata = {
                            customMetadata: {
                                filename: this.attach[i].name,
                            },
                        }
                        await leadDocRef.put(this.attach[i], metadata)
                    }
                    this.lead.docs = this.attach
                }
                if (this.lead.userId != this.originalLead.userId) {
                    this.lead.collaborators.push(this.lead.userId)
                    this.lead.collaborators = this.lead.collaborators.filter(
                        u => u != this.originalLead.userId
                    )
                }
                if (this.leadDiff.tags) {
                    this.leadDiff.tags = this.lead.tags
                }
                // Assign only the name of the city
                const lead = await API.updateLead({
                    id: this.lead.id,
                    ...this.leadDiff,
                })
                this.$emit('updateLead', lead)
                this.closeDialog()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        getUser(id) {
            const user = this.users.find(u => u.id == id)
            if (user) {
                return user.name
            }
            return null
        },
        update() {
            this.isEditing = !this.isEditing
        },
        async deleteAllFilesInPath(path, files) {
            try {
                const storageRef = storage().ref(path)
                // List all files and directories on path
                for (const file of files) {
                    const docRef = storageRef.child(file.name)
                    await docRef.delete()
                }
            } catch (error) {
                console.error(`Failed to delete files in path ${path}: `, error)
            }
        },
        getClients: async function() {
            try {
                this.loading = true
                this.loadingError = false
                this.errorMsg = null
                const {
                    data: { clients },
                } = await API.getClients({ companyId: this.company })
                this.clients = clients
                this.addClientsAction(clients)
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        selectState: function(event) {
            const index = this.listCountries.findIndex(x => x.name === event)
            this.shortNameCity = this.listCountries[index].shortName
            this.states = countrycitystate.getStatesByShort(this.shortNameCity)
        },
        selectCity: function(event) {
            this.cities[0] = { header: 'Select or create one' }
            let data = countrycitystate.getCities(this.shortNameCity, event)
            if (data.length == 0) {
                this.cities = []
            }
            data.forEach(city => {
                this.cities.push({ text: city })
            })
        },
        filter(item, queryText, itemText) {
            if (item.header) return false
            const hasValue = val => (val != null ? val : '')
            const text = hasValue(itemText)
            const query = hasValue(queryText)

            return (
                text
                    .toString()
                    .toLowerCase()
                    .indexOf(query.toString().toLowerCase()) > -1
            )
        },
        deleteSearch() {
            this.searchContacts = ''
        },
    },
    async created() {
        if (this.originalLead) {
            this.lead = _.cloneDeep(this.originalLead)
        }
        this.listCountries = countrycitystate.getCountries()
        this.countries = this.listCountries.map(x => x.name)
        this.itemsCurrency = codesCurrency.map(x => x.AlphabeticCode)
        const indexExchanges = this.settings.findIndex(
            u => u.name === 'Exchange'
        )
        const settingExchange = this.settings[indexExchanges]
        this.lead.currency = settingExchange.globalCurrency
        const exchanges = settingExchange.exchanges
        const index = exchanges.findIndex(
            u => u.currency === this.lead.currency
        )
        if (index >= 0) {
            this.lead.exchange = parseInt(exchanges[index].money)
        } else {
            this.lead.exchange = 0
        }
        await this.getClients()
    },
}
</script>
