<template>
    <v-container fluid>
        <v-card flat>
            <v-card-title class="py-3 pl-0" ref="title">
                <div class="d-flex ml-n2">
                    <v-btn small icon @click="closeLead">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <span v-if="lead">{{ lead.name }}</span>
                </div>

                <v-spacer />
                <v-menu v-if="quoteDetails && !lead.archive" rounded offset-y>
                    <template v-slot:activator="{ attrs, on }">
                        <v-btn
                            v-if="lead.status != 'rejected'"
                            fab
                            v-bind="attrs"
                            v-on="on"
                            small
                            color="grey lighten-3"
                            depressed
                            class="mx-1 mr-n7"
                        >
                            <v-icon>mdi-dots-horizontal</v-icon>
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item
                            v-if="lead.status != 'rejected'"
                            @click.stop="editLead(lead)"
                        >
                            <v-list-item-title>
                                Edit
                            </v-list-item-title>
                        </v-list-item>

                        <v-list-item
                            v-if="
                                lead.status == 'onHold' ||
                                    lead.status == 'approved'
                            "
                            @click.stop="sendToInProgressAlert(lead)"
                        >
                            <v-list-item-title>
                                Active
                            </v-list-item-title>
                        </v-list-item>

                        <v-list-item
                            v-if="lead.status != 'onHold'"
                            @click.stop="sendToOnHoldAlert(lead)"
                        >
                            <v-list-item-title>
                                On Hold
                            </v-list-item-title>
                        </v-list-item>

                        <v-list-item
                            @click.stop="sendToApproveAlert(lead)"
                            v-if="
                                lead.status == 'onHold' ||
                                    lead.status == 'inProgress'
                            "
                        >
                            <v-list-item-title>
                                Approve
                            </v-list-item-title>
                        </v-list-item>

                        <v-list-item
                            @click.stop="openSetPromiseForm(lead)"
                            v-if="lead.status == 'approved'"
                        >
                            <v-list-item-title>
                                For Bidding
                            </v-list-item-title>
                        </v-list-item>

                        <v-list-item
                            v-if="lead.status != 'rejected'"
                            @click.stop="sendToRejectedAlert(lead)"
                        >
                            <v-list-item-title>
                                Reject
                            </v-list-item-title>
                        </v-list-item>

                        <v-list-item
                            v-if="lead.status != 'rejected'"
                            @click.stop="deleteAlert(lead)"
                        >
                            <v-list-item-title>
                                Delete
                            </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-card-title>
            <v-row v-if="$vuetify.breakpoint.smAndDown">
                <v-col cols="12">
                    <span class="mr-3 font-weight-bold">Status:</span>
                    <v-btn
                        v-if="lead"
                        style="width: 100px;"
                        rounded
                        small
                        elevation="0"
                        :color="getColor(lead.status)"
                        class="text-center btnStatus white--text"
                    >
                        {{ lead.status }}
                    </v-btn>
                </v-col>
            </v-row>
            <v-row no-gutter>
                <v-col cols="12" xl="6" lg="6" md="6">
                    <!-- Company details -->
                    <v-row class="mb-3" no-gutters>
                        <v-col
                            cols="4"
                            xl="2"
                            lg="2"
                            md="2"
                            sm="2"
                            class="d-flex align-center"
                        >
                            <span class="font-weight-bold">ID</span>
                        </v-col>
                        <v-col
                            cols="6"
                            xl="1"
                            lg="1"
                            md="1"
                            sm="1"
                            class="d-flex align-center"
                        >
                            <span v-if="lead">{{
                                lead.number ? lead.number : lead.shortId
                            }}</span>
                        </v-col>
                    </v-row>
                    <v-row class="" no-gutters>
                        <v-col
                            cols="4"
                            xl="2"
                            lg="2"
                            md="2"
                            sm="2"
                            class="d-flex align-center"
                        >
                            <span class="font-weight-bold"
                                >Account <br />
                                Manager</span
                            >
                        </v-col>
                        <v-col class="d-flex  flex-wrap">
                            <template>
                                <v-card flat>
                                    <div
                                        v-if="lead.mainContact"
                                        class="d-flex my-1"
                                        style="cursor: pointer"
                                        @click="openChangeMainContact(lead)"
                                    >
                                        <v-avatar
                                            v-if="lead.mainContact"
                                            size="34"
                                            color="grey lighten-4"
                                            class="mr-1 ml-1"
                                        >
                                            <v-img
                                                v-if="
                                                    lead.mainContact
                                                        .profilePicURL
                                                "
                                                :src="
                                                    lead.mainContact
                                                        .profilePicURL
                                                "
                                            />
                                            <span v-else class="text-h6">
                                                {{
                                                    lead.mainContact.name
                                                        .split(' ')
                                                        .map((n, i, a) =>
                                                            i === 0 ||
                                                            i + 1 === a.length
                                                                ? n[0]
                                                                : null
                                                        )
                                                        .join('')
                                                        .toUpperCase()
                                                }}
                                            </span>
                                        </v-avatar>
                                        <div
                                            class="d-flex flex-column justify-space-around me-4"
                                        >
                                            <span
                                                v-if="lead"
                                                class="font-weight-bold text-singleline"
                                            >
                                                {{ lead.mainContact.name }}
                                            </span>
                                            <span
                                                v-if="lead"
                                                class="text-caption text-singleline"
                                            >
                                                {{ lead.mainContact.role }}
                                            </span>
                                        </div>
                                    </div>
                                </v-card>
                            </template>
                        </v-col>
                        <!--Change Main Contact-->
                        <v-dialog
                            :retain-focus="false"
                            v-model="changeMainContact"
                            persistent
                            max-width="400px"
                        >
                            <ChangeMainContactLeads
                                v-if="lead && changeMainContact"
                                :leadId="lead.id"
                                :lead="lead"
                                @closeChangeMainContact="closeChangeMainContact"
                                @replaceMainContact="replaceMainContact"
                            />
                        </v-dialog>
                    </v-row>
                    <v-row no-gutters>
                        <v-col
                            cols="4"
                            xl="2"
                            lg="2"
                            md="2"
                            sm="2"
                            class="d-flex align-center"
                        >
                            <span class="font-weight-bold">Collaborators</span>
                        </v-col>
                        <v-col class="d-flex align-center flex-wrap">
                            <v-card
                                v-for="(collaborator, index) in filterDataColab"
                                :key="index"
                                flat
                                class="mr-1 ml-1"
                            >
                                <div class="d-flex my-1">
                                    <v-avatar
                                        color="grey lighten-4"
                                        size="34"
                                        class="mr-1"
                                    >
                                        <v-img
                                            v-if="collaborator.profilePicURL"
                                            :src="collaborator.profilePicURL"
                                        />
                                        <span v-else class="text-h6">
                                            {{
                                                collaborator.name
                                                    .split(' ')
                                                    .map((n, i, a) =>
                                                        i === 0 ||
                                                        i + 1 === a.length
                                                            ? n[0]
                                                            : null
                                                    )
                                                    .join('')
                                                    .toUpperCase()
                                            }}
                                        </span>
                                    </v-avatar>
                                    <div
                                        class="d-flex flex-column justify-space-around me-4"
                                    >
                                        <span
                                            v-if="lead"
                                            class="font-weight-bold text-singleline"
                                        >
                                            {{ collaborator.name }}
                                        </span>
                                        <span
                                            v-if="lead"
                                            class="text-caption text-singleline"
                                        >
                                            {{ collaborator.role }}
                                        </span>
                                    </div>
                                </div>
                            </v-card>
                            <v-btn
                                v-if="
                                    lead &&
                                        lead.status != 'rejected' &&
                                        lead.status != 'approved'
                                "
                                @click="openDialogCollaborator"
                                class="my-2 ml-2"
                                depressed
                                fab
                                x-small
                                color="primary"
                            >
                                <v-icon>mdi-plus</v-icon>
                            </v-btn>
                        </v-col>
                        <v-dialog
                            :retain-focus="false"
                            v-model="addDialog"
                            persistent
                            max-width="400px"
                        >
                            <v-card>
                                <v-card-title class="text-h5"
                                    >Add Collaborator</v-card-title
                                >
                                <v-card-text>
                                    <v-form ref="collaborator">
                                        <v-autocomplete
                                            v-if="lead && lead.mainContact"
                                            @change="deleteSearch"
                                            :search-input.sync="search"
                                            label="Collaborator"
                                            v-model="lead.collaborators"
                                            :items="
                                                collaborators.filter(
                                                    c =>
                                                        c.id !=
                                                        lead.mainContact.id
                                                )
                                            "
                                            item-text="name"
                                            chips
                                            item-value="id"
                                            multiple
                                        >
                                        </v-autocomplete>
                                    </v-form>
                                </v-card-text>
                                <v-card-actions>
                                    <v-btn
                                        text
                                        color="secondary"
                                        @click="closeDialog"
                                    >
                                        Close
                                    </v-btn>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        @click="addCollaborator"
                                        text
                                        color="error"
                                        :loading="loading"
                                    >
                                        Save
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-row>

                    <v-row class="mb-4" no-gutters>
                        <v-col
                            cols="4"
                            xl="2"
                            lg="2"
                            md="2"
                            sm="2"
                            class="d-flex align-center"
                        >
                            <span class="font-weight-bold">Bid Documents</span>
                        </v-col>
                        <v-col
                            v-if="lead"
                            class="d-flex align-center flex-wrap"
                        >
                            <v-btn
                                color="grey lighten-3"
                                elevation="0"
                                x-small
                                class="ma-1 text-capitalize grey--text text--darken-1  "
                                v-for="(file, i) in lead.docs"
                                :key="i"
                            >
                                <span @click="openFile(file.file)">{{
                                    file.name
                                }}</span>
                                <v-icon
                                    v-if="lead && lead.status != 'rejected'"
                                    @click="deleteDocQuote(file.file)"
                                    class="ml-1"
                                    small
                                >
                                    mdi-close-circle
                                </v-icon>
                            </v-btn>
                            <v-btn
                                @click="openQuoteDocument"
                                depressed
                                fab
                                x-small
                                color="primary"
                                class="ml-xl-2 ml-lg-2 ml-md-2 ml-sm-2"
                                v-if="
                                    lead &&
                                        lead.status != 'rejected' &&
                                        lead.status != 'approved'
                                "
                            >
                                <v-icon>mdi-plus</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-dialog
                        :retain-focus="false"
                        v-model="deleteDialog"
                        persistent
                        max-width="400px"
                    >
                        <v-card>
                            <v-card-title class="text-h5"
                                >Delete Document</v-card-title
                            >
                            <v-card-text>
                                Are you sure you want to delete this document?
                            </v-card-text>
                            <v-card-actions>
                                <v-btn
                                    text
                                    color="secondary"
                                    @click="deleteDialog = false"
                                >
                                    Close
                                </v-btn>
                                <v-spacer></v-spacer>
                                <v-btn @click="deleteDoc" text color="error">
                                    Delete
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                    <DocumentForm
                        v-if="lead"
                        v-model="dialogDocument"
                        :title="title"
                        :create-form="true"
                        @closeDialog="closeDialogDocument"
                        formUser=""
                        :quoteId="lead.id"
                        :quoteFiles="lead.files"
                        @addFile="addFile"
                    />
                    <v-row class="mb-4" no-gutters>
                        <v-col
                            cols="3"
                            xl="2"
                            lg="2"
                            md="2"
                            sm="2"
                            class="d-flex align-center"
                        >
                            <span class="font-weight-bold">Attached</span>
                        </v-col>
                        <v-col
                            v-if="lead"
                            class="d-flex align-center flex-wrap"
                        >
                            <v-btn
                                color="grey lighten-3"
                                elevation="0"
                                x-small
                                class="ma-1 text-capitalize grey--text text--darken-1"
                                v-for="(file, i) in lead.attached"
                                :key="i"
                            >
                                <span @click="openFile(file.file)">{{
                                    file.name
                                }}</span>
                                <v-icon
                                    v-if="lead && lead.status != 'rejected'"
                                    @click="deleteDocQuote(file.file)"
                                    class="ml-1"
                                    small
                                >
                                    mdi-close-circle
                                </v-icon>
                            </v-btn>
                            <v-btn
                                v-if="
                                    lead &&
                                        lead.status != 'rejected' &&
                                        lead.status != 'approved'
                                "
                                @click="openAttachDocument"
                                depressed
                                fab
                                x-small
                                class="ml-xl-2 ml-lg-2 ml-md-2 ml-sm-2"
                                color="primary"
                            >
                                <v-icon>mdi-plus</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-row class="mb-4" no-gutters>
                        <v-col
                            cols="3"
                            xl="2"
                            lg="2"
                            md="2"
                            sm="2"
                            class="d-flex align-center"
                        >
                            <span class="font-weight-bold">Tags</span>
                        </v-col>
                        <v-col
                            cols="9"
                            xl="10"
                            lg="10"
                            md="10"
                            sm="10"
                            class="d-flex"
                        >
                            <v-btn
                                color="grey lighten-3"
                                elevation="0"
                                x-small
                                class="ma-1 text-capitalize grey--text text--darken-1  "
                                v-for="(tag, i) in lead.tags"
                                :key="i"
                            >
                                <span>{{ tag }}</span>
                                <v-icon
                                    v-if="lead && lead.status != 'rejected'"
                                    @click="deleteQuoteTag(tag)"
                                    class="ml-1"
                                    small
                                >
                                    mdi-close-circle
                                </v-icon>
                            </v-btn>
                            <v-btn
                                @click="openTagsForm"
                                depressed
                                fab
                                x-small
                                color="primary"
                                class="ml-xl-2 ml-lg-2 ml-md-2 ml-sm-2"
                                v-if="
                                    lead &&
                                        lead.status != 'rejected' &&
                                        lead.status != 'approved'
                                "
                            >
                                <v-icon>mdi-plus</v-icon>
                            </v-btn>
                        </v-col>
                        <!-- TAGS FORM -->
                        <v-dialog
                            :retain-focus="false"
                            v-model="tagsDialog"
                            persistent
                            max-width="400px"
                        >
                            <TagsForm
                                v-if="tagsDialog"
                                :setting="selectedSettings"
                                :quoteTags="lead.tags"
                                @closeTagsDialog="closeTagsDialog"
                                @saveTags="saveTags"
                            />
                        </v-dialog>
                    </v-row>
                    <v-row v-if="lead" class="mb-4" no-gutters>
                        <v-col
                            cols="3"
                            xl="2"
                            lg="2"
                            md="2"
                            sm="2"
                            class="d-flex align-center"
                        >
                            <span class="font-weight-bold">Country</span>
                        </v-col>
                        <v-col
                            cols="12"
                            xl="4"
                            lg="4"
                            md="4"
                            sm="12"
                            class="justify-left"
                        >
                            <v-autocomplete
                                :disabled="
                                    lead.status == 'rejected' ||
                                        lead.status == 'approved'
                                "
                                v-model="lead.country"
                                :items="this.countries"
                                prepend-icon="mdi-earth"
                                label="Country"
                                required
                                readonly
                            />
                        </v-col>
                    </v-row>
                    <v-row v-if="lead" class="mb-4" no-gutters>
                        <v-col
                            cols="3"
                            xl="2"
                            lg="2"
                            md="2"
                            sm="2"
                            class="d-flex align-center"
                        >
                            <span class="font-weight-bold">State</span>
                        </v-col>
                        <v-col cols="12" xl="4" lg="4" md="4" sm="12">
                            <v-autocomplete
                                :disabled="
                                    lead.status == 'rejected' ||
                                        lead.status == 'approved'
                                "
                                v-model="lead.state"
                                :items="this.states"
                                prepend-icon="mdi-map-marker-radius"
                                label="State"
                                required
                                readonly
                            />
                        </v-col>
                    </v-row>
                    <v-row v-if="lead" class="mb-4" no-gutters>
                        <v-col
                            cols="3"
                            xl="2"
                            lg="2"
                            md="2"
                            sm="2"
                            class="d-flex align-center"
                        >
                            <span class="font-weight-bold">City</span>
                        </v-col>
                        <v-col cols="12" xl="4" lg="4" md="4" sm="12">
                            <v-combobox
                                :disabled="
                                    lead.status == 'rejected' ||
                                        lead.status == 'approved'
                                "
                                v-model="lead.city"
                                :filter="filter"
                                :hide-no-data="!searchLocation"
                                :items="this.cities"
                                hide-details
                                prepend-icon="mdi-city"
                                :search-input.sync="searchLocation"
                                hide-selected
                                label="City"
                                required
                                flat
                                readonly
                            >
                                <template v-slot:item="{ index, item }">
                                    <v-text-field
                                        v-if="editing === item"
                                        v-model="editing.text"
                                        autofocus
                                        flat
                                        background-color="transparent"
                                        hide-details
                                        solo
                                        @keyup.enter="edit(index, item)"
                                    ></v-text-field>
                                    <span v-else>
                                        {{ item.text }}
                                    </span>
                                    <v-spacer></v-spacer>
                                </template>
                            </v-combobox>
                        </v-col>
                    </v-row>
                </v-col>

                <!-- Client Information from here -->
                <v-col cols="12" xl="6" lg="6" md="6" class="pr-0">
                    <v-row no-gutters>
                        <v-col cols="12" xl="10" lg="10" md="10" sm="10">
                            <v-row
                                v-if="!loading"
                                class="align-center font-weight-bold"
                            >
                                <v-col>
                                    <span>Client Information</span>
                                </v-col>
                            </v-row>
                            <v-row
                                v-if="!loading"
                                class="align-center mt-3 mb-4"
                                no-gutters
                            >
                                <v-col
                                    cols="4"
                                    xl="2"
                                    lg="2"
                                    md="2"
                                    sm="2"
                                    class="d-flex align-center"
                                >
                                    <span>Company</span>
                                </v-col>
                                <v-col>
                                    <span v-if="lead">{{
                                        lead.client.name
                                    }}</span>
                                </v-col>
                            </v-row>
                            <v-row v-if="!loading" class="my-5" no-gutters>
                                <v-col
                                    cols="2"
                                    xl="2"
                                    lg="2"
                                    md="2"
                                    sm="2"
                                    class="d-flex align-center"
                                >
                                    <span>City</span>
                                </v-col>
                                <v-col v-if="lead" class="d-flex align-center">
                                    <span v-if="lead.client">{{
                                        lead.client.city
                                    }}</span>
                                </v-col>
                            </v-row>
                            <v-row v-if="!loading" class="my-4" no-gutters>
                                <v-col
                                    cols="3"
                                    xl="2"
                                    lg="2"
                                    md="2"
                                    sm="2"
                                    class="d-flex align-center"
                                >
                                    <span>Language</span>
                                </v-col>
                                <v-col
                                    v-if="lead.client.language"
                                    class="d-flex"
                                >
                                    <v-avatar
                                        v-if="lead.client"
                                        size="33"
                                        class="me-3 ml-1"
                                    >
                                        <v-img
                                            :src="
                                                lead.client.language.toLowerCase() ===
                                                'english'
                                                    ? require('@/assets/usa_flag.png')
                                                    : require('@/assets/col_flag.png')
                                            "
                                        />
                                    </v-avatar>
                                    <span v-if="lead.client">{{
                                        lead.client.language
                                    }}</span>
                                </v-col>
                            </v-row>
                            <v-row v-if="!loading" class="my-2" no-gutters>
                                <v-col
                                    cols="3"
                                    xl="2"
                                    lg="2"
                                    md="2"
                                    sm="2"
                                    class="d-flex align-top"
                                >
                                    <span>Contacts</span>
                                </v-col>
                                <v-col
                                    cols="9"
                                    xl="10"
                                    lg="10"
                                    md="10"
                                    sm="10"
                                    v-if="lead"
                                    class="d-flex align-center flex-wrap"
                                    style="row-gap: 20px; max-height: 500px; overflow-y: auto ;"
                                >
                                    <v-progress-linear
                                        v-if="contactsProgress"
                                        indeterminate
                                    />
                                    <v-card
                                        v-else
                                        flat
                                        class="mr-1"
                                        style="width: 100%;"
                                        v-for="(contact,
                                        index) in selectedContactsList"
                                        :key="index"
                                    >
                                        <div v-if="contact" class="d-flex">
                                            <v-avatar
                                                color="grey lighten-4"
                                                size="34"
                                                class="mr-1"
                                            >
                                                <v-img
                                                    v-if="contact.profilePicURL"
                                                    :src="contact.profilePicURL"
                                                    :alt="contact.name"
                                                />
                                                <span v-else class="text-h6">
                                                    {{
                                                        contact.name
                                                            .split(' ')
                                                            .map((n, i, a) =>
                                                                i === 0 ||
                                                                i + 1 ===
                                                                    a.length
                                                                    ? n[0]
                                                                    : null
                                                            )
                                                            .join('')
                                                            .toUpperCase()
                                                    }}
                                                </span>
                                            </v-avatar>
                                            <div
                                                class="d-flex flex-column justify-space-around me-4"
                                            >
                                                <span
                                                    class="font-weight-bold text-singleline"
                                                >
                                                    {{ contact.name }}
                                                    {{
                                                        contact.role
                                                            ? `(${contact.role})`
                                                            : ''
                                                    }}
                                                </span>
                                                <span
                                                    class="text-caption text-singleline"
                                                >
                                                    {{ contact.phone }}
                                                    {{
                                                        contact.phone2
                                                            ? `- ${contact.phone2}`
                                                            : ''
                                                    }}
                                                </span>
                                                <span
                                                    class="text-caption text-singleline"
                                                >
                                                    {{ contact.email }}
                                                </span>
                                            </div>
                                        </div>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col
                            cols="12"
                            xl="2"
                            lg="2"
                            md="2"
                            sm="2"
                            class="d-flex justify-start justify-lg-end justify-md-end justify-xl-end justify-sm-end"
                            v-if="!$vuetify.breakpoint.smAndDown"
                        >
                            <span class="mr-3 font-weight-bold">Status:</span>
                            <v-btn
                                v-if="lead"
                                style="width: 100px;"
                                rounded
                                small
                                elevation="0"
                                :color="getColor(lead.status)"
                                class="text-center btnStatus white--text"
                            >
                                {{ lead.status }}
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-row class="mt-5 mb-2" no-gutters>
                        <v-col cols="4">
                            <span class="font-weight-bold">Last updated</span>
                            <br />
                            <span v-if="lead">{{
                                lead.updatedOn
                                    ? formatDate(lead.updatedOn._seconds)
                                    : formatDate(lead.createdOn._seconds)
                            }}</span>
                        </v-col>
                        <v-col cols="4">
                            <span class="font-weight-bold"
                                >Invitation Date</span
                            >
                            <br />
                            <span v-if="lead">{{
                                lead.invitationDate
                                    ? formatDate(
                                          lead.invitationDate._seconds + 60
                                      )
                                    : ''
                            }}</span>
                        </v-col>
                        <v-col cols="4">
                            <span class="font-weight-bold">Due Date</span>
                            <br />
                            <span v-if="lead">{{
                                lead.requestDate
                                    ? formatDate(lead.requestDate._seconds + 60)
                                    : ''
                            }}</span>
                        </v-col>
                    </v-row>
                    <v-row v-if="lead" class="mr-3" no-gutters>
                        <v-col cols="12">
                            <v-textarea
                                :disabled="
                                    lead.status == 'rejected' ||
                                        lead.status == 'approved'
                                "
                                name="input-7-1"
                                v-model="lead.notes"
                                hide-details
                                label="Notes"
                                rows="4"
                            >
                            </v-textarea>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-card>
        <v-divider class="ml-4 mr-5" />
        <v-btn
            v-if="false"
            @click="openCreateItem"
            class="mx-1"
            depressed
            rounded
            color="success"
            >Create Item</v-btn
        >

        <div>
            <LeadsCosts
                :lead="lead"
                :createItemBtn="true"
                :showTitle="true"
                :user="user"
                :users="users"
            />
        </div>
        <v-dialog
            :retain-focus="false"
            v-model="createDialog"
            persistent
            max-width="500px"
        >
            <QuoteForm
                v-if="createDialog"
                :title="title"
                :originalLead="selectedLead"
                :createForm="createForm"
                :settings="settings"
                @updateLead="updateThisLead"
                @closeDialog="closeCreateDialog"
            />
        </v-dialog>
        <!--Send to another state-->
        <v-dialog
            v-model="sendWarning"
            persistent
            max-width="400px"
            :retain-focus="false"
        >
            <v-card :loading="loading" v-if="sendWarning">
                <v-card-title
                    v-if="onHold || approve || inProgress"
                    class="text-h5"
                    >Send Request</v-card-title
                >
                <v-card-title v-if="reject" class="text-h5"
                    >Reject Request</v-card-title
                >
                <v-card-title v-if="toDelete" class="text-h5"
                    >Delete Request</v-card-title
                >
                <v-card-text v-if="onHold">
                    Are you sure you want to send to On Hold?
                </v-card-text>
                <v-card-text v-if="approve">
                    Are you sure you want to send to Approve?
                </v-card-text>
                <v-card-text v-if="reject">
                    Are you sure you want to reject the request?
                </v-card-text>
                <v-card-text v-if="toDelete">
                    Are you sure you want to delete the request?
                </v-card-text>
                <v-card-text v-if="inProgress">
                    Are you sure you want to send to Active?
                </v-card-text>
                <v-card-actions>
                    <v-btn text color="secondary" @click="closeAlert">
                        Close
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        v-if="onHold || inProgress"
                        @click="sendToAnotherStatus"
                        text
                        color="error"
                        :loading="loading"
                    >
                        Send
                    </v-btn>
                    <v-btn
                        v-if="approve"
                        @click="sendToApprove"
                        text
                        color="error"
                        :loading="loading"
                    >
                        Send
                    </v-btn>
                    <v-btn
                        v-if="reject"
                        @click="sendToRejected"
                        text
                        color="error"
                        :loading="loading"
                    >
                        Reject
                    </v-btn>
                    <v-btn
                        v-if="toDelete"
                        @click="deleteLead"
                        text
                        color="error"
                        :loading="loading"
                    >
                        Delete
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-alert
            :type="action.type"
            :color="action.color"
            style="position: absolute; bottom: 0; right: 0;"
            dismissible
            v-if="action.active && action.type == 'error'"
        >
            {{ action.message }}
        </v-alert>
    </v-container>
</template>

<script>
import API from '@/services/api'
import { storage } from '@/services/firebase'
import { mapState, mapMutations } from 'vuex'
import moment from 'moment'
import _ from 'lodash'
import DocumentForm from '@/components/Settings/DocumentForm'
const countrycitystate = require('countrycitystatejson')
import { loadImage } from '@/helpers/imageHandler'
import LeadsCosts from '@/components/Leads/LeadsCosts'
import QuoteForm from '@/components/Quotes/QuoteForm'

export default {
    name: 'LeadsGeneralInfo',
    props: {
        id: String,
        lead: Object,
        settings: Array,
        costVersions: Object,
        costs: Array,
        clients: Array,
        user: Object,
        users: Array,
        history: {
            type: Boolean,
            default: false,
        },
        action: {
            type: Object,
            default() {
                return {}
            },
        },
    },
    components: {
        DocumentForm,
        ChangeMainContactLeads: () =>
            import('@/components/Leads/ChangeMainContactLeads'),
        TagsForm: () => import('@/components/Settings/TagsForm'),
        LeadsCosts,
        QuoteForm,
    },
    data() {
        return {
            companyId: JSON.parse(localStorage.getItem('company')),
            folderDocuments: 'leads_documents',
            loading: false,
            files: [],
            addDialog: false,
            contacts: [],
            indexDoc: null,
            indexAttached: null,
            indexDocs: null,
            title: '',
            deleteDialog: false,
            search: '',
            dialogDocument: false,
            docQuote: null,
            contactsProgress: false,
            folderUsersPictures: 'users_pictures',
            collaborators: [],
            document: {},
            loadingError: false,
            errorMsg: null,
            error: false,
            rules: {
                required: v => !!v || 'Required',
                number: v => (v <= 100 && v >= 0) || 'Error',
            },
            folderLeads: 'leads_documents',
            listCountries: null,
            countries: [],
            cities: [],
            states: [],
            shortNameCity: null,
            editing: null,
            searchLocation: null,
            oldQuote: Object.assign({}, this.lead),
            changeMainContact: false,
            tagsDialog: false,
            selectedSettings: undefined,
            dataCollaborators: [],
            readClients: false,
            quoteDetails: false,
            createItem: false,
            createForm: null,
            selectedLead: this.lead,
            createDialog: false,
            sendWarning: false,
            onHold: false,
            reject: false,
            selectedItem: {},
            client: undefined,
            state: '',
            originalLead: this.lead,
            approve: false,
            inProgress: false,
            toDelete: false,
        }
    },
    inject: {
        replaceQuote: {
            from: 'replaceQuote',
            default: () => null,
        },
    },
    computed: {
        ...mapState(['  Btn', 'costQuotes', 'quotes']),
        filterDataColab() {
            return this.dataCollaborators.filter(c => {
                return c === undefined
                    ? false
                    : c.id != this.lead.mainContact.id
            })
        },
        selectedContactsList() {
            let contactObjects = []
            this.lead.contacts.forEach(id => {
                const contact = this.contacts.find(contact => contact.id == id)
                if (contact) {
                    contactObjects.push(contact)
                }
            })
            return contactObjects
        },
    },
    watch: {
        settings: function() {
            this.setSettings()
        },
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        showStatus(status) {
            if (status.toLowerCase() == 'inprogress') {
                return 'inProgress'
            }
            if (status.toLowerCase() == 'onhold') {
                return 'onHold'
            }
            return status
        },
        async updateThisLead(lead) {
            try {
                Object.keys(lead).forEach(key => {
                    this.lead[key] = lead[key]
                })
                this.loading = true
                if (lead.clientId) {
                    this.client = this.clients.find(
                        client => client.id == lead.clientId
                    )
                    this.contactsProgress = true
                    const {
                        data: { contacts },
                    } = await API.getContactsByClient({
                        clientId: lead.clientId,
                    })
                    this.contacts = contacts
                    this.contactsProgress = false
                    this.lead.client = this.client
                }
                if (lead.accountManager) {
                    this.lead.mainContact = this.users.find(
                        u => u.id === lead.userId
                    )
                }
                if (lead.country) {
                    this.selectState(lead.country)
                    // load cities
                    if (lead.state) {
                        this.selectCity(lead.state)
                    }
                }
                if (lead.requestDate) {
                    this.lead.requestDateShow = this.formatDate2(
                        lead.requestDate._seconds
                    )
                }
                if (lead.invitationDate) {
                    this.lead.invitationDateShow = this.formatDate2(
                        lead.invitationDate._seconds
                    )
                }
                this.closeCreateDialog()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
                this.action = {
                    active: true,
                    type: 'error',
                    message: 'An error occurred while update lead',
                    color: 'error',
                }
            } finally {
                this.loading = false
            }
        },
        formatDate2(seconds) {
            return `${moment.unix(seconds).format('L')}`
        },
        formatDate3(seconds) {
            return `${moment.unix(seconds).format('YYYY-MM-DD')}`
        },
        closeLead() {
            this.$emit('closeLead')
        },
        editLead(item) {
            this.selectedLead = _.cloneDeep(item)
            this.title = 'Edit Lead'
            this.createForm = false
            this.createDialog = true
        },
        closeCreateDialog() {
            this.createDialog = false
            this.createForm = false
            this.selectedLead = {}
        },
        sendToOnHoldAlert(item) {
            this.selectedItem = _.cloneDeep(item)
            this.onHold = true
            this.sendWarning = true
        },
        sendToInProgressAlert(item) {
            this.selectedItem = _.cloneDeep(item)
            this.inProgress = true
            this.sendWarning = true
        },
        sendToApproveAlert(item) {
            this.selectedItem = _.cloneDeep(item)
            this.approve = true
            this.sendWarning = true
        },
        sendToRejectedAlert(item) {
            this.selectedItem = _.cloneDeep(item)
            this.reject = true
            this.sendWarning = true
        },
        deleteAlert(item) {
            this.selectedItem = _.cloneDeep(item)
            this.toDelete = true
            this.sendWarning = true
        },
        async sendToAnotherStatus() {
            try {
                this.loading = true
                if (this.onHold) {
                    this.selectedItem.status = 'onHold'
                }
                if (this.inProgress) {
                    this.selectedItem.status = 'inProgress'
                }
                await API.updateLead({
                    id: this.selectedItem.id,
                    status: this.selectedItem.status,
                })
                this.$emit('removeLead', this.selectedItem.id)
                this.closeLead()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async sendToApprove() {
            try {
                this.loading = true
                if (
                    this.selectedItem.status == 'inProgress' ||
                    this.selectedItem.status == 'onHold'
                ) {
                    this.selectedItem.status = 'approved'
                }
                await API.approveLead({
                    id: this.selectedItem.id,
                })
                this.$emit('removeLead', this.selectedItem.id)
                this.closeLead()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
                this.action = {
                    active: true,
                    type: 'error',
                    message: error.message,
                    color: 'error',
                }
            } finally {
                this.loading = false
            }
        },
        async sendToRejected() {
            try {
                this.loading = true
                await API.rejectLead({
                    id: this.selectedItem.id,
                })
                this.$emit('removeLead', this.selectedItem.id)
                this.closeLead()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async deleteLead() {
            try {
                this.loading = true
                await API.deleteLead({
                    id: this.selectedItem.id,
                })
                this.$emit('removeLead', this.selectedItem.id)
                this.closeLead()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        closeAlert() {
            this.selectedItem = {}
            this.sendWarning = false
            if (this.onHold) {
                this.onHold = false
            } else {
                this.reject = false
            }
        },
        async saveTags(tags) {
            try {
                this.error = false
                this.errorMsg = ''
                await API.updateLead({
                    id: this.lead.id,
                    notificationId: 'LrubMkCSfpNwEoXemQKL',
                    tags,
                })
                this.lead.tags = [...tags]
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        openSetPromiseForm(item) {
            this.$emit('setPromiseDate', item)
        },
        closeTagsDialog() {
            this.tagsDialog = false
        },
        openTagsForm() {
            this.selectedSettings = this.settings.find(
                setting => setting.name === 'Company'
            )
            if (!this.lead.tags) {
                this.lead.tags = []
            }
            this.tagsDialog = true
        },
        async deleteQuoteTag(tag) {
            try {
                this.loading = true
                this.errorMsg = ''
                const tags = this.lead.tags.filter(t => t !== tag)
                await API.updateLead({
                    id: this.lead.id,
                    notificationId: 'LrubMkCSfpNwEoXemQKL',
                    tags,
                })
                this.lead.tags = [...tags]
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        replaceMainContact(mainContact) {
            this.lead.mainContact = mainContact
            this.lead.accountManager = mainContact.name
            this.lead.userId = mainContact.id
        },
        closeChangeMainContact() {
            this.changeMainContact = false
        },
        openChangeMainContact(lead) {
            if (lead.status == 'rejected') {
                return null
            }
            this.changeMainContact = true
        },
        getColor(status) {
            if (status == 'OPEN') return 'primary'
            else if (status == 'AWARDED') return 'success'
            else if (status == 'LOST') return 'error'
            else if (status == 'SENT') return 'warning'
            else return 'orange'
        },
        deleteSearch() {
            this.search = ''
        },
        openDialogCollaborator() {
            if (!this.lead.collaborators) {
                Object.defineProperty(this.lead, 'collaborators', {
                    writable: true,
                })
            }
            if (!this.lead.dataCollaborators) {
                Object.defineProperty(this.lead, 'dataCollaborators', {
                    value: [],
                    writable: true,
                })
            }
            this.addDialog = true
        },
        closeDialog() {
            this.addDialog = false
        },
        openQuoteDocument() {
            this.dialogDocument = true
            this.title = 'Documents Leads'
        },
        openAttachDocument() {
            this.dialogDocument = true
            this.title = 'Attach Leads'
        },

        deleteDocQuote(file) {
            this.docToDelete = file
            this.indexDoc = this.lead.files.findIndex(
                x => x.file == this.docToDelete
            )
            this.indexAttached = this.lead.attached.findIndex(
                x => x.file == this.docToDelete
            )
            this.indexDocs = this.lead.docs.findIndex(
                x => x.file == this.docToDelete
            )
            this.deleteDialog = true
        },
        objectsEqual(o1, o2) {
            typeof o1 === 'object' && Object.keys(o1).length > 0
                ? Object.keys(o1).length === Object.keys(o2).length &&
                  Object.keys(o1).every(p => this.objectsEqual(o1[p], o2[p]))
                : o1 === o2
        },
        formatDate(seconds) {
            return moment.unix(seconds).format('MMM/DD/YYYY')
        },
        updateLead: async function() {
            try {
                this.loading = true
                this.error = false
                this.errorMsg = null
                if (!this.lead.collaborators) {
                    this.lead.collaborators = []
                    this.lead.collaborators.splice(0, 0, this.lead.userId)
                } else if (
                    !this.lead.collaborators.includes(this.lead.userId)
                ) {
                    this.lead.collaborators.splice(0, 0, this.lead.userId)
                }
                let changes = {}
                Object.keys(this.lead).forEach(key => {
                    if (
                        key !== 'clients' &&
                        key !== 'client' &&
                        ((this.oldQuote[key] === undefined &&
                            this.lead[key] !== undefined) ||
                            (this.lead[key] !== undefined &&
                                JSON.stringify(this.lead[key]) !==
                                    JSON.stringify(this.oldQuote[key])) ||
                            (Array.isArray(this.lead[key]) &&
                                !this.objectsEqual(
                                    this.lead[key],
                                    this.oldQuote[key]
                                )))
                    ) {
                        changes[key] =
                            key === 'city'
                                ? this.lead[key].text
                                : this.lead[key]
                    }
                })
                if (
                    !this.originalLead.collaborators.includes(this.user.id) &&
                    !this.user.permissions.includes('updateAllLeads')
                ) {
                    if (changes.collaborators) {
                        this.lead.collaborators = this.originalLead.collaborators
                    }
                }
                const lead = await API.updateLead({
                    id: this.lead.id,
                    ...changes,
                })
                Object.keys(lead).forEach(key => (this.lead[key] = lead[key]))
            } catch (error) {
                console.log(error)
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
                this.deleteDialog = false
            }
        },
        deleteDoc: async function() {
            this.lead.files.splice(this.indexDoc, 1)
            //this.lead.docs.splice(this.indexDoc, 1)
            this.updateLead()
            if (this.indexAttached >= 0) {
                this.lead.attached.splice(this.indexAttached, 1)
            } else if (this.indexDocs >= 0) {
                this.lead.docs.splice(this.indexDocs, 1)
            }
        },

        closeDialogDocument() {
            this.dialogDocument = false
        },
        closeItemFormDialog() {
            this.createItem = false
            this.createForm = null
        },
        openCreateItem() {
            this.createForm = true
            this.createItem = true
        },
        addFile(file) {
            if (file.attach) {
                this.lead.attached.push(file)
            } else {
                this.lead.docs.push(file)
            }
        },

        addCollaborator: async function() {
            try {
                this.loading = true
                this.lead.dataCollaborators = []
                for (const collaboratorId of this.lead.collaborators) {
                    let dataUser
                    if (this.lead.users && this.lead.users.length > 0) {
                        dataUser = this.lead.users.find(
                            user => collaboratorId == user.id
                        )
                    } else {
                        dataUser = this.users.find(
                            user => collaboratorId == user.id
                        )
                    }
                    if (!dataUser.profilePicURL && dataUser.picture) {
                        dataUser.profilePicURL = await loadImage(
                            `${this.companyId}/${this.folderUsersPictures}`,
                            dataUser.picture
                        )
                    }
                    this.lead.dataCollaborators.push(dataUser)
                }
                this.dataCollaborators = [...this.lead.dataCollaborators]
                await this.updateLead()

                this.addDialog = false
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },

        selectStatus(event) {
            this.lead.status = event
        },

        openFile: async function(file) {
            const id = this.lead.id
            const path = `${this.companyId}/${this.folderDocuments}/${id}`
            const storageRef = storage().ref(path)
            const documentRef = storageRef.child(file)
            await documentRef
                .getDownloadURL()
                .then(url => {
                    this.document.url = url
                })
                .catch(() => {
                    this.document.url = ''
                })
            setTimeout(() => {
                window.open(this.document.url, '_blank')
            })
        },
        setSettings() {
            const indexExchanges = this.settings.findIndex(
                u => u.name === 'Exchange'
            )
            this.settingExchange = this.settings[indexExchanges]
        },
        getUsers: async function() {
            try {
                this.loadingError = false
                this.errorMsg = null
                this.loading = true
                let usersIdsBySearch = [...(this.lead.collaborators || [])]
                for (const userId of usersIdsBySearch) {
                    const index = this.users.findIndex(u => u.id == userId)
                    if (index > -1) {
                        if (this.users[index].picture) {
                            this.users[index].profilePicURL = await loadImage(
                                `${this.companyId}/${this.folderUsersPictures}`,
                                this.users[index].picture
                            )
                        }
                        this.users[index].createdOn = moment
                            .unix(this.users[index].createdOn.seconds)
                            .format('MMM/DD/YYYY HH:mm')
                    }
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async getDataContacts() {
            if (this.users.length <= 0) {
                await this.getUsers()
            }
            this.lead.users = _.cloneDeep(this.users)
            this.lead.attached = this.lead.files.filter(x => x.attach == true)
            this.lead.docs = this.lead.files.filter(
                x => x.attach == false || x.attach == undefined
            )

            if (this.lead.collaborators) {
                this.lead.dataCollaborators = []
                this.lead.collaborators.forEach(element => {
                    const dataUser = this.users.find(user => element == user.id)
                    if (dataUser) this.lead.dataCollaborators.push(dataUser)
                })
                this.dataCollaborators = [...this.lead.dataCollaborators]
            }
            if (this.lead.followUpCollaborators) {
                this.lead.followUpDataCollaborators = []
                this.lead.followUpCollaborators.forEach(element => {
                    const dataUser = this.users.find(user => element == user.id)
                    if (dataUser)
                        this.lead.followUpDataCollaborators.push(dataUser)
                })
            }
            if (this.lead.userId) {
                this.lead.mainContact = this.users.find(
                    user => this.lead.userId == user.id
                )
            }
            this.collaborators = _.cloneDeep(this.lead.users)
            const indexMainContact = this.collaborators.findIndex(
                user => user.id == this.id
            )
            if (indexMainContact !== -1) {
                this.collaborators.splice(indexMainContact, 1)
            }
        },
        selectState: function(event) {
            const index = this.listCountries.findIndex(x => x.name === event)
            this.shortNameCity = this.listCountries[index].shortName
            this.states = countrycitystate.getStatesByShort(this.shortNameCity)
        },
        selectCity: function(event) {
            this.cities[0] = { header: 'Select or create one' }
            let data = countrycitystate.getCities(this.shortNameCity, event)
            if (data.length == 0) {
                this.cities = []
            }
            data.forEach(city => {
                this.cities.push({ text: city })
            })
        },
        filter(item, queryText, itemText) {
            if (item.header) return false
            const hasValue = val => (val != null ? val : '')
            const text = hasValue(itemText)
            const query = hasValue(queryText)

            return (
                text
                    .toString()
                    .toLowerCase()
                    .indexOf(query.toString().toLowerCase()) > -1
            )
        },
    },

    async mounted() {
        try {
            //this.state = this.lead.state ? this.lead.state : ''
            this.client = this.clients.find(
                client => client.id == this.lead.clientId
            )
            if (!this.client) {
                const {
                    data: { clients },
                } = await API.getClient({ clientId: this.lead.clientId })
                this.client = clients
            }
            this.lead.client = this.client
            this.listCountries = countrycitystate.getCountries()
            this.countries = this.listCountries.map(x => x.name)
            this.loading = true
            this.loadingError = false
            this.error = false
            this.errorMsg = null
            if (this.users.length == 0) {
                await this.getUsers()
                if (this.lead) {
                    await this.getDataContacts()
                }
            } else {
                this.lead.users = this.users
                if (this.lead) {
                    await this.getDataContacts()
                }
            }
            if (this.settings) {
                await this.setSettings()
            }
            if (this.lead && this.lead.country) {
                this.selectState(this.lead.country)
                // load cities
                if (this.lead.state) {
                    this.selectCity(this.lead.state)
                }
            }
            if (
                this.lead.dataCollaborators &&
                this.lead.dataCollaborators.length > 0
            ) {
                this.dataCollaborators = [...this.lead.dataCollaborators]
            }
            const readClients = this.user.permissions.find(
                x => x == 'readClients'
            )
            this.quoteDetails = this.user.permissions.includes('quoteDetails')
            if (readClients) {
                this.readClients = true
            }
            this.contactsProgress = true
            const {
                data: { contacts },
            } = await API.getContactsByClient({
                clientId: this.lead.clientId,
            })
            this.contacts = contacts
            this.contactsProgress = false
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
}
</script>

<style scoped>
.text-singleline {
    line-height: 0.75rem;
}
.btnStatus {
    cursor: default;
}
.content {
    overflow-x: auto;
}
.button {
    background: gray;
}
.v-text-field.v-input--dense:not(.v-text-field--outlined) input {
    padding: 0px;
}
</style>
